@tailwind base;
@tailwind components;
@tailwind utilities;


a{
    text-decoration: none;
}

:hover a{
    text-decoration: none !important;
}